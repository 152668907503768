import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { 
  getFirestore, 
  collection, addDoc, doc,
  query, where, getDocs, updateDoc,getDoc, deleteDoc 
} from 'firebase/firestore';
import CryptoJS from 'crypto-js';
import { firebaseApp } from "./firebase";

const secretKey = '05ac300126e14cfe5c75a2377f917abe2c830ae8807bc340c5141a075d5997f3';
const auth = getAuth(firebaseApp)
const firestore = getFirestore();


export const criarUserFirebase = async (email, senha) =>{
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, senha);
    return userCredential.user;
  } catch (error) {
    throw new Error('Erro ao criar usuário no Firebase Authentication: ' + error.message);
  }
}

export const enviarEmailResetSenha = async (email) => {
  try {
    return await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw new Error('Erro ao criar enviar email de reset senha: ' + error.message);
  }
}

export const salvarInformacoesNoFirestore = async (values) => {
  try {
    const docRef = await addDoc(collection(firestore, 'distribuidoras'), {...values, password: '#secret#'});
    return docRef.id;
  } catch (error) {
    throw new Error('Erro ao salvar informações no Firestore: ' + error.message);
  }
};

export const atualizarInformacoesFireStore = async (valuesToUpdate) => {
  const uid = valuesToUpdate.uid || null;

  try {
    // Consulta para obter o documento com o UID fornecido
    const q = query(collection(firestore, 'distribuidoras'), where('uid', '==', uid));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      // Atualiza cada documento retornado pela consulta
      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, valuesToUpdate);
      });
    } else {
      console.log(`Nenhum cliente encontrado com o UID ${uid}.`);
    }
  } catch (error) {
    throw new Error('Erro ao atualizar informações no Firestore: ' + error.message);
  }
};

export const obterDadosCliente = async (uid)=> {
  try {
    const q = query(collection(firestore, 'distribuidoras'), where('uid', '==', uid));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const primeiroDocumento = querySnapshot.docs[0];
      const dadosCliente = primeiroDocumento.data();
      return dadosCliente;
    } else {
      return null;
    }
  } catch (error) {
    return null;
    // throw new Error('Erro ao obter informações do Firestore: ' + error.message);
  }
};

export const obterDadosAdmin = async (uid)=> {
  try {
    const q = query(collection(firestore, 'admin'), where('uid', '==', uid));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const primeiroDocumento = querySnapshot.docs[0];
      const dadosCliente = primeiroDocumento.data();
      return dadosCliente;
    } else {
      return null;
    }
  } catch (error) {
    return null;
    // throw new Error('Erro ao obter informações do Firestore: ' + error.message);
  }
};

export const listarTodosClientes = async ()=> {
  try {
    const q = query(collection(firestore, 'distribuidoras'));

    const querySnapshot = await getDocs(q);
    
    const listaClientes = [];

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const dadosCliente = doc.data();
        listaClientes.push(dadosCliente);
      });

      return listaClientes;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Erro ao obter informações do Firestore: ' + error.message);
  }
};

export const excluirClienteUsuario = async (uid) =>{
  try {
    const uid_encrypted = CryptoJS.AES.encrypt(uid, secretKey).toString();

    const q = query(collection(firestore, 'distribuidoras'), where('uid', '==', uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        await fetch(`https://weblue-back.vercel.app/api/delete`,{
          method: 'DELETE', 
          body: uid_encrypted.toString()
        })
        await deleteDoc(doc.ref);
      });
    }
  } catch (error) {
    throw new Error('Erro ao excluir usuário: ' + error.message);
  }
}

export const salvarTelaNaUrna = async (values) => {
  try {
    const docRef = await addDoc(collection(firestore, 'registroTelas'), { ...values });
    return docRef.id;
  } catch (error) {
    throw new Error('Erro ao salvar informações no Firestore: ' + error.message);
  }
};

export const listarPedidosByUid = async (uid)=> {
  try {
    const q = query(collection(firestore, 'registroTelas'), where('uid', '==', uid));

    const querySnapshot = await getDocs(q);
    
    const listaClientes = [];

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const dadosCliente = doc.data();
        listaClientes.push({
          id: doc.id,
          ref: doc.ref,
          ...dadosCliente
        });
      });

      return listaClientes;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Erro ao obter informações do Firestore: ' + error.message);
  }
};
export const listarPedidos = async ()=> {
  try {
    const q = query(collection(firestore, 'registroTelas'), where('status', '==', 'APROVADO'));

    const querySnapshot = await getDocs(q);
    
    const listaClientes = [];

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        const dadosCliente = doc.data();
        listaClientes.push({
          id: doc.id,
          ...dadosCliente
        });
      });

      return listaClientes;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Erro ao obter informações do Firestore: ' + error.message);
  }
};

export const excluirPedido = async (ref) =>{
  try {
    await deleteDoc(ref);
  } catch (error) {
    throw new Error('Erro ao excluir pedido: ' + error.message);
  }
}

export const atualizarPedido = async (valuesToUpdate) => {
  const {ref, id, ...values} = valuesToUpdate;

  try {
    await updateDoc(ref, values);
  } catch (error) {
    throw new Error('Erro ao atualizar informações: ' + error.message);
  }
};

export const obterDadosPedido = async (id)=> {
  try {
    const docRef = doc(firestore, 'registroTelas', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        ref: docRef,
        ...docSnap.data()
      }
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Erro ao obter informações do Firestore: ' + error.message);
  }
};

