import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useContext, useEffect, useState } from "react";
import { Icon, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { excluirPedido } from "services/clientes";
import MDSnackbar from "components/MDSnackbar";
import { listarPedidosByUid } from "services/clientes";
import { AuthContext } from "context";
import { atualizarPedido } from "services/clientes";
import Modal from "components/Modal";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

export default function data() {
  const authContext = useContext(AuthContext);
  const { profile } = authContext;

  const navigate = useNavigate();
  const [values, setValues] = useState([]);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertErrorCheck, setAlertErrorCheck] = useState(false);
  const [alertSuccessCheck, setAlertSuccessCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);
  const [error, setError] = useState(null);
  const [numeroTelas, setNumeroTelas] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({});
  
  const closeErrorSBCheck = () => setAlertErrorCheck(false);
  const closeSuccessSB = () => setAlertSuccess(false);
  const closeErrorSB = () => setAlertError(false);
  const closeSuccessCheck = () => setAlertSuccessCheck(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleCloseDelete = () => setShowModalDelete(false);
  const handleShowDelete = () => setShowModalDelete(true);

  useEffect(()=>{
    async function fecthData () {
      try{
        const body = await listarPedidosByUid(profile.uid);
        const response = body ? body.map((item) => ({
          author: { name: item.nome },
          function: { title: item.telefone },
          status: { online: item.status.toUpperCase() === 'APROVADO' },
          contract: { qtdTelas: item.telas.map(tela=> parseInt(tela.qtd, 10)).reduce((acc, current) => acc + current, 0)},
          fullReturn: {
            ...item
          }
        })) : [] ;
        setValues(response)
      } catch(error){
        console.log(error)
      }
    }
    if(profile.uid){
      fecthData();
    }
  },[profile.uid])

  const onClickEdit = (item) => {
    navigate(`/cadastroPedidos/${item.fullReturn.id}`);
  }

  const doApproveDelete = async () => {
    try{   
      await excluirPedido(itemToDelete.fullReturn.ref)
      setAlertSuccess(true);
      setValues(values.filter((i) => i.fullReturn.id !== itemToDelete.fullReturn.id))
      setItemToDelete({});
    } catch(error){
      console.log(error)
      setAlertError(true)
    }
  }

  const onClickDelete = async (item) => {
    handleShowDelete();
    setItemToDelete(item);
  }

  const doApprove = async (item) =>{
    if(numeroTelas > item.contract.qtdTelas){
      setError("Número de telas informado maior que o registrado anteriormente");
      return;
    }
    try{
      setError("");
      setValues(values.map(i=> {
        if(i.fullReturn.id === item.fullReturn.id){
          i.status.online = true;
          i.fullReturn.status = 'APROVADO'
        }
        return i
      }))
      await atualizarPedido({
        ...item.fullReturn, 
        qtdTelasWeFix: numeroTelas || 0, 
        status: 'APROVADO' 
      })
      setNumeroTelas(0);
      setAlertSuccessCheck(true);
      handleClose();
    } catch(error){
      console.log(error)
      setAlertError(true);
    }
  }

  const onClickApprove = (item)=> {
    setModalDetails(item);
    setNumeroTelas(null);
    setError(null);
    handleShow()
  };

  const renderError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Erro ao remover pedido"
      dateTime="Agora"
      open={alertError}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  )


  const renderSuccess = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Pedido removido com sucesso"
      dateTime="Agora"
      open={alertSuccess}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorCheck = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Erro ao aprovar pedido"
      dateTime="Agora"
      open={alertErrorCheck}
      onClose={closeErrorSBCheck}
      close={closeErrorSBCheck}
      bgWhite
    />
  )

  const renderSuccessCheck = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Pedido aprovado"
      dateTime="Agora"
      open={alertSuccessCheck}
      onClose={closeSuccessCheck}
      close={closeSuccessCheck}
      bgWhite
    />
  )

  const renderModalDelete = (
    <Modal
      title='Excluir registro'
      onClose={handleCloseDelete}
      show={showModalDelete}
      footer={
        <>
          <MDBox mt={0} mb={1} >
            <MDButton variant="gradient" color="error" type="button" onClick={()=>doApproveDelete()}>
              Excluir
            </MDButton>
          </MDBox>
        </>
      }
    >
      <>
        <MDBox mb={2}>
          <MDBox lineHeight={3} textAlign="left">
            <MDTypography variant="h6" color="text">
              Deseja excluir o registro de tela ?
            </MDTypography>
          </MDBox>
        </MDBox>
      </>
    </Modal>
  )

  return {
    rows: values.map((item)=>({
      razao: <RazaoSocial name={item.author.name}/>,
      telefone: <Telefone title={item.function.title} />,
      status: <Status online={item.status.online} />,
      telas: <ItemTelas qtd={item.contract.qtdTelas} />,
      action:
      <>
        {!item.status.online && 
          <IconButton size="small" aria-label="close" color="inherit" onClick={()=>onClickApprove(item)}>
            <Icon fontSize="small">check</Icon>
          </IconButton>
        }
        <IconButton size="small" aria-label="close" color="inherit" onClick={()=>onClickEdit(item)}>
          <Icon fontSize="small">edit</Icon>
        </IconButton>
        <IconButton size="small" aria-label="close" color="inherit" onClick={()=>onClickDelete(item)}>
          <Icon fontSize="small">delete</Icon>
        </IconButton>
        {renderSuccess}
        {renderError}
        {renderSuccessCheck}
        {renderErrorCheck}
        {renderModalDelete}
        <Modal
          title='Aprovar Pedido'
          onClose={handleClose}
          show={showModal}
          footer={
            <>
              <MDBox mt={0} mb={1} >
                <MDButton variant="gradient" color="info" type="button" onClick={()=>doApprove(modalDetails)}>
                  Salvar
                </MDButton>
              </MDBox>
            </>
          }
        >
          <>
            <MDBox mb={2}>
              <MDBox lineHeight={3} textAlign="left">
                <MDTypography variant="h6" color="text">
                  Dentre as {modalDetails?.contract?.qtdTelas} telas informadas quantas são da WeFix / WK / Nn
                </MDTypography>
              </MDBox>
              <MDInput
                type="number"
                label="Nº de telas WeFix/WK/Nn"
                variant="standard"
                fullWidth
                name="telasWeFix"
                value={numeroTelas}
                onChange={e=>setNumeroTelas(e.target.value)}
              />
              {error && 
                <MDTypography variant="caption" color="error" fontWeight="light">
                  {error}
                </MDTypography>
              }
            </MDBox>
          </>
        </Modal>
      </>
    }))
  };
}


const RazaoSocial = ({ name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
    </MDBox>
  </MDBox>
);

const Telefone = ({ title }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
  </MDBox>
);

const ItemTelas = ({ qtd }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {qtd}
    </MDTypography>
  </MDBox>
);

const Status = ({online}) => (
  <MDBox ml={-1}>
    <MDBadge 
      badgeContent={online ? "APROVADO" : "PENDENTE"} 
      color={online ? "success" : "error"} 
      variant="gradient" size="sm" 
    />
  </MDBox>
)